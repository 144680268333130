<template>
  <div id="ErrorPage">
    <div>
      <h2>404</h2>
      <p>
        Page Not Found
      </p>
      <router-link class="btn more_btn " to="/">HOME</router-link>
    </div>
  </div>
</template>

<script>
import { GetMetaData } from '@/common/meta.js';
export default {
  name: 'ErrorPage',
  data() {
    return {
      meta_data: null,
    };
  },
  metaInfo() {
    return this.meta_data;
  },
  created() {
    let meta_list = document.getElementsByTagName('meta');
    let meta_exist = false;
    for (let i = 0; i < meta_list.length; i++) {
      meta_list[i].name == 'prerender-status-code' &&
      meta_list[i].content == '404'
        ? (meta_exist = true)
        : '';
    }
    if (!meta_exist) {
      var meta = document.createElement('meta');
      meta.setAttribute('name', 'prerender-status-code');
      meta.content = '404';
      document.getElementsByTagName('head')[0].appendChild(meta);
    }
    this.meta_data = GetMetaData('Page Not Found', 'Page Not Found', '');
    this.$nextTick(() => {
      window.prerenderReady = true;
    });
  },
};
</script>
